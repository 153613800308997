<template>
  <v-dialog v-model="toShow" max-width="55%" persistent>
    <v-card>
      <v-card-title style="padding: 16px !important" class="headline bg-clr">
        Upload Image
        <v-spacer />
        <div @click="closeDialog">
          <v-icon class="pointer">close</v-icon>
        </div>
      </v-card-title>
      <v-spacer>
        <v-progress-linear
          indeterminate
          color="trukkin-theme darken-2"
          v-show="loading"
        ></v-progress-linear>
        <v-card-text>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            onsubmit="return false;"
          >
            <v-layout style="height: 450px">
              <div class="image-container">
                <h1 v-if="!loading">Image</h1>
                <img
                  ref="image"
                  :src="src"
                  style="margin-top: 8px"
                  height="35"
                  width="90"
                />
              </div>
              <div v-if="!loading">
                <h1 style="padding-left: 40px">Preview</h1>

                <img
                  v-if="destination"
                  :src="destination"
                  class="img-preview"
                />
              </div>
            </v-layout>
            <v-btn
              color="orange darken-1"
              class="white--text"
              @click="getFileFormat(destination, src)"
              :loading="loading"
              >Save</v-btn
            >
          </v-form>
        </v-card-text>
      </v-spacer>
    </v-card>
    <v-snackbar
      :timeout="3000"
      bottom
      color="red darken-2"
      v-model="error"
      class="white--text"
      v-if="error"
      >{{ error }}</v-snackbar
    >
  </v-dialog>
</template>

<script>
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
export default {
  watch: {
    toShow(val) {
      if (!val) {
        this.closeDialog();
      }
      if (val) {
      }
    },
  },
  data() {
    return {
      valid: true,
      lazy: false,
      error: null,
      finalUrl: {},
      cropper: {},
      loading: false,
      destination: {},
      image: {},
    };
  },
  props: {
    src: String,
    index: Number,
    toShow: Boolean,
    status: Number,
    imageCrop: String,
  },
  mounted() {
    if (!navigator.onLine) {
      this.loading = false;
      this.error = "Please check your internet connection";
      setTimeout(() => {
        this.error = "";
      }, 3000);
      return;
    }

    this.loading = true;
    this.image = this.$refs.image;
    let link = this.src.name;
    this.cropper = new Cropper(this.image, {
      checkCrossOrigin: true,
      zoomable: false,
      scalable: false,
      useCORS: true,
      aspectRatio: 1,
      crop: () => {
        this.loading = false;
        const canvas = this.cropper.getCroppedCanvas();
        this.destination = canvas.toDataURL("image/png");
      },
    });

    if (
      this.cropper.originalUrl
        .toString()
        .substr(this.cropper.originalUrl.toString().lastIndexOf(".") + 1) !==
        "png" &&
      this.cropper.originalUrl
        .toString()
        .substr(this.cropper.originalUrl.toString().lastIndexOf(".") + 1) !==
        "jpg" &&
      this.cropper.originalUrl
        .toString()
        .substr(this.cropper.originalUrl.toString().lastIndexOf(".") + 1) !==
        "jpeg" &&
      this.cropper.originalUrl
        .toString()
        .substr(this.cropper.originalUrl.toString().lastIndexOf(".") + 1) !==
        "pdf"
    ) {
      this.loading = false;
      this.$eventBus.$emit("error-image");
      this.closeDialog();
      return false;
    }
  },

  methods: {
    closeDialog() {
      this.$eventBus.$emit("cropper-close");
    },
    getFileFormat(dataurl, filename) {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      this.finalUrl = new File([u8arr], filename, { type: mime });
      if (this.finalUrl) {
        this.cropImage();
      }
    },
    cropImage() {
      if (!navigator.onLine) {
        this.loading = false;
        this.error = "Please check your internet connection";
        setTimeout(() => {
          this.error = "";
        }, 3000);
        return;
      }

      if (this.status == 1) {
        this.$eventBus.$emit("crop-profile-image", this.finalUrl, index);
      }
      if (this.status == 2) {
        this.$eventBus.$emit("crop-national-image", this.finalUrl);
      }
      if (this.status == 3) {
        this.$eventBus.$emit("crop-passport-image", this.finalUrl);
      }
      if (this.status == 4) {
        this.$eventBus.$emit("crop-license-image", this.finalUrl);
      }
      if (this.status == 5) {
        this.$eventBus.$emit("crop-vehicleRegImage-image", this.finalUrl);
      }
      if (this.status == 6) {
        this.$eventBus.$emit("crop-vehicleInsuranceImage-image", this.finalUrl);
      }
      if (this.status == 7) {
        this.$eventBus.$emit(
          "crop-vehicleAuthLetterImage-image",
          this.finalUrl
        );
      }
      if (this.status == 8) {
        this.$eventBus.$emit("crop-licenseImageBack-image", this.finalUrl);
      }
      if (this.status == 9) {
        this.$eventBus.$emit("crop-vehicleRegImageBack-image", this.finalUrl);
      }
      if (this.status == 10) {
        this.$eventBus.$emit("crop-emiratesIDImageFront-image", this.finalUrl);
      }
      if (this.status == 11) {
        this.$eventBus.$emit("crop-emiratesIDImageBack-image", this.finalUrl);
      }
      if (this.status == 12) {
        this.$eventBus.$emit("crop-profile-image", {
          url: this.finalUrl,
          i: this.index,
        });
      }
    },
  },
};
</script>

<style scoped>
.image-container {
  width: 500px;
  height: 400px;
}
.img-preview {
  width: 200px;
  height: 200px;
  float: left;
  margin-left: 40px;
  border: 2px solid;
}
</style>
